import { BrowserRouter as Router, Routes, Route } from "react-router-dom"

import { Suspense, lazy } from "react"
import LoadingComponent from "./components/loading/LoadingComponent"
import "react-datepicker/dist/react-datepicker.css";

const HomePage = lazy(() => import("./pages/homepage/HomePage"))
const Navbar = lazy(() => import("./layouts/navbar/Navbar"))
const MediaWiseNews = lazy(() => import("./pages/media-wise-news/MediaWiseNews"))
const NewsSearch = lazy(() => import("./pages/search-news/NewsSearch"))
const MediaWiseLinks = lazy(() => import("./pages/media-wise-links/MediaWiseLinks"))
const NotFoundPage = lazy(() => import("./pages/not-found-404/NotFoundPage"))
const AboutPage = lazy(() => import("./pages/about-us/AboutPage"))
const AlertPreviewPage = lazy(() => import("./pages/todays-alert/AlertPreviewPage"))
const AlertSearchPage = lazy(() => import("./pages/alert-search/AlertSearchPage"))

function App() {
  return (
    <Router>
      <Navbar />
      
      <Suspense fallback={<LoadingComponent />}>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/todays-alert" element={<AlertPreviewPage />} />
          <Route path="/media-wise-news" element={<MediaWiseNews />} />
          <Route path="/media-wise-links" element={<MediaWiseLinks />} />
          <Route path="/news-search" element={<NewsSearch />} />
          <Route path="/alert-search" element={<AlertSearchPage />} />
          <Route path="/about-ais" element={<AboutPage />} />
          {/* <Route path="*" element={<NotFoundPage />} /> */}
        </Routes>
      </Suspense>
    </Router>
  )
}

export default App
