import React from "react";
import "./loading.css";

export default function LoadingComponent() {
    return (
        <div className="loading-wrapper">
            <div className="loading-text">Loading...</div>
            <div className="loading-shape"></div>
        </div>
    );
}
